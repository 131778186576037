import request from '@/utils/request'


// 查询培训项目列表
export function listTraining(query) {
  return request({
    url: '/driving-school/driving-school-training/list',
    method: 'get',
    params: query
  })
}

// 查询培训项目分页
export function pageTraining(query) {
  return request({
    url: '/driving-school/driving-school-training/page',
    method: 'get',
    params: query
  })
}

// 查询培训项目详细
export function getTraining(data) {
  return request({
    url: '/driving-school/driving-school-training/detail',
    method: 'get',
    params: data
  })
}

// 新增培训项目
export function addTraining(data) {
  return request({
    url: '/driving-school/driving-school-training/add',
    method: 'post',
    data: data
  })
}

// 修改培训项目
export function updateTraining(data) {
  return request({
    url: '/driving-school/driving-school-training/edit',
    method: 'post',
    data: data
  })
}

// 删除培训项目
export function delTraining(data) {
  return request({
    url: '/driving-school/driving-school-training/delete',
    method: 'post',
    data: data
  })
}
