<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="培训名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入培训名称" />
      </a-form-model-item>
      <a-form-model-item label="主图" prop="pic" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          :customRequest="handleChange"
          v-model="form.imgUrl"
        >
          <img v-if="form.pic" :src="form.pic" alt="轮播图"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="价格" prop="price" >
        <a-input v-model="form.price" placeholder="请输入价格" />
      </a-form-model-item>
          <!--<a-form-model-item label="分期期数" prop="lists" >-->
            <!--<div v-for='(list,index) in lists' :key='index'>-->
              <!--<input v-model="list.percent" placeholder='输入0-100的数字' />-->
              <!--<span v-if="index==0"  @click="addNumber">+</span>-->
              <!--<span v-else @click="delNumber(index)">-</span>-->
            <!--</div>-->
          <!--</a-form-model-item>-->



        <a-form-model-item label="返现奖励" prop="rewardMechanism" >
          <!--<span>第1人奖励：</span>-->
          <!--<a-input  type="number" v-model="params" palceholder="请输入params" :style="{width: '200px'}" >-->
          <!--</a-input>-->
          <!--&#12288;-->
          <a-button @click="addItem" type="primary"> 增加奖励金额</a-button>
        </a-form-model-item>

      <div v-for="(item, index) in dynamicItem" :key="index"  >
        <a-form-model-item
          :prop="'dynamicItem.' + index + '.params'"
        >
          <span>第{{index+1}}人奖励：</span>
          <a-input type="number" :style="{width: '200px'}" v-model="item.params"></a-input>
          <a-button @click="deleteItem(item, index)" type="danger">删除</a-button>
        </a-form-model-item>
      </div>

      <a-form-model-item label="项目介绍" prop="introduce" >
        <a-input v-model="form.introduce" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="学科" prop="course" >
        <a-select
          mode="multiple"
          v-model="form.course"
          placeholder="请选择学科"
        >
          <a-select-option v-for="(d, index) in courseOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <a-form-model-item label="首页推荐" prop="recommend" >
        <a-radio-group v-model="form.recommend" button-style="solid">
          <a-radio-button v-for="(d, index) in recommendOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTraining, addTraining, updateTraining } from '@/api/driving-school/training'
import ossUpload from "@/utils/ossUpload";

export default {
  name: 'CreateForm',
  props: {
    courseOptions: {
      type: Array,
      required: true
    },
    recommendOptions: {
      type: Array,
      required: true
    },
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      uploadLoading: false,
      formTitle: '',
      params: '',
      dynamicItem:[{ params: "1"}],

      // 表单参数
      form: {
        id: null,

        name: null,

        rewardMechanism: null,

        pic: null,

        price: null,

        introduce: null,

        course: [],

        createTime: null,

        remark: null,

        recommend: '0'

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true);
      });
    },
    handleChange(info) { //上传图片回调
      debugger
      let that = this;
      this.uploadLoading = true;

      ossUpload.ossUpload(info.file, function (fileName) {
        that.uploadLoading = false;
        console.log(fileName)
        that.form.pic = "https://ccjp.oss-cn-beijing.aliyuncs.com/"+fileName; //文件名
        console.log('1111', that.form.pic)
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.dynamicItem = [{ params: 1}]
      this.form = {
        id: null,
        name: null,
        pic: null,
        price: null,
        introduce: null,
        course: [],
        createTime: null,
        remark: null,
        rewardMechanism: null,
        recommend: '0'
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTraining({"id":id}).then(response => {
        this.form = response.data
        if (this.form.course !== null) {
          this.form.course = this.form.course.split(',')
        } else {
          this.form.course = undefined
        }
        if (this.form.rewardMechanism !== null) {
          this.dynamicItem = JSON.parse(this.form.rewardMechanism)
          console.log(2222222,this.dynamicItem)
        }

        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.course = this.form.course.join(',')
          this.form.rewardMechanism = JSON.stringify(this.dynamicItem)
          console.log(1111111111111,this.form.rewardMechanism)
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTraining(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTraining(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    addItem () {
      this.dynamicItem.push({
        params: this.params + 1
      })
    },
    deleteItem (item, index) {
      this.dynamicItem.splice(index, 1)
    }
  }
}
</script>
